import axios from "axios";
import { HOST_URL } from "../shared/env";
import { REACT_APP_API_KEY } from "../shared/env";

const axiosInstance = axios.create({
  baseURL: HOST_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    "X-API-KEY": REACT_APP_API_KEY,
  },
});
export default axiosInstance;
